/* vietnamese */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/75cde960c2842862-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1ff84563a719c397-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4c7655c11f7bd97b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Anton_Fallback_6fce18';src: local("Arial");ascent-override: 127.81%;descent-override: 35.76%;line-gap-override: 0.00%;size-adjust: 92.04%
}.__className_6fce18 {font-family: '__Anton_6fce18', '__Anton_Fallback_6fce18';font-weight: 400;font-style: normal
}

/* thai */
@font-face {
  font-family: '__Maitree_af8a63';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/6138c9abaf585dc3-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Maitree_af8a63';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/725610af6d7e9dc0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Maitree_af8a63';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/e0f2cc7e044d2ead-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Maitree_af8a63';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/6d2d08ab767fe252-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Maitree_Fallback_af8a63';src: local("Times New Roman");ascent-override: 97.85%;descent-override: 42.54%;line-gap-override: 0.00%;size-adjust: 117.53%
}.__className_af8a63 {font-family: '__Maitree_af8a63', '__Maitree_Fallback_af8a63';font-weight: 200;font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/75cde960c2842862-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1ff84563a719c397-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4c7655c11f7bd97b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Anton_Fallback_6fce18';src: local("Arial");ascent-override: 127.81%;descent-override: 35.76%;line-gap-override: 0.00%;size-adjust: 92.04%
}.__className_6fce18 {font-family: '__Anton_6fce18', '__Anton_Fallback_6fce18';font-weight: 400;font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/75cde960c2842862-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1ff84563a719c397-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4c7655c11f7bd97b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Anton_Fallback_6fce18';src: local("Arial");ascent-override: 127.81%;descent-override: 35.76%;line-gap-override: 0.00%;size-adjust: 92.04%
}.__className_6fce18 {font-family: '__Anton_6fce18', '__Anton_Fallback_6fce18';font-weight: 400;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_Sans_f418d6';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/583e141224f61ace-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_Sans_f418d6';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/26813eeabdf142eb-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_Sans_f418d6';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e966ba19c8856819-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_Sans_f418d6';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/ab748541eb4e47c6-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_Sans_f418d6';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/23d817876504828e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Nunito_Sans_Fallback_f418d6';src: local("Arial");ascent-override: 99.39%;descent-override: 34.70%;line-gap-override: 0.00%;size-adjust: 101.72%
}.__className_f418d6 {font-family: '__Nunito_Sans_f418d6', '__Nunito_Sans_Fallback_f418d6';font-weight: 600;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_Sans_45514b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/91da210830fde368-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_Sans_45514b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/2e89d1420926fd62-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_Sans_45514b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/1df443147fda9dd6-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_Sans_45514b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/5ac4b55274d78358-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_Sans_45514b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e15b1cfda85f6579-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Nunito_Sans_Fallback_45514b';src: local("Arial");ascent-override: 99.39%;descent-override: 34.70%;line-gap-override: 0.00%;size-adjust: 101.72%
}.__className_45514b {font-family: '__Nunito_Sans_45514b', '__Nunito_Sans_Fallback_45514b';font-weight: 300;font-style: normal
}

/* thai */
@font-face {
  font-family: '__Maitree_54afb6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7f51ce006c61ba63-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Maitree_54afb6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/eaca0311b07f8476-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Maitree_54afb6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/8a6e9cb6b2bae1e5-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Maitree_54afb6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/6b86e1d722146340-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Maitree_Fallback_54afb6';src: local("Times New Roman");ascent-override: 97.85%;descent-override: 42.54%;line-gap-override: 0.00%;size-adjust: 117.53%
}.__className_54afb6 {font-family: '__Maitree_54afb6', '__Maitree_Fallback_54afb6';font-weight: 300;font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/75cde960c2842862-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1ff84563a719c397-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4c7655c11f7bd97b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Anton_Fallback_6fce18';src: local("Arial");ascent-override: 127.81%;descent-override: 35.76%;line-gap-override: 0.00%;size-adjust: 92.04%
}.__className_6fce18 {font-family: '__Anton_6fce18', '__Anton_Fallback_6fce18';font-weight: 400;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_Sans_45514b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/91da210830fde368-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_Sans_45514b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/2e89d1420926fd62-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_Sans_45514b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/1df443147fda9dd6-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_Sans_45514b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/5ac4b55274d78358-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_Sans_45514b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e15b1cfda85f6579-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Nunito_Sans_Fallback_45514b';src: local("Arial");ascent-override: 99.39%;descent-override: 34.70%;line-gap-override: 0.00%;size-adjust: 101.72%
}.__className_45514b {font-family: '__Nunito_Sans_45514b', '__Nunito_Sans_Fallback_45514b';font-weight: 300;font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/75cde960c2842862-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1ff84563a719c397-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4c7655c11f7bd97b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Anton_Fallback_6fce18';src: local("Arial");ascent-override: 127.81%;descent-override: 35.76%;line-gap-override: 0.00%;size-adjust: 92.04%
}.__className_6fce18 {font-family: '__Anton_6fce18', '__Anton_Fallback_6fce18';font-weight: 400;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_Sans_bb89b5';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/49ee8fa951650317-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_Sans_bb89b5';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/ff92c3c5aed9b140-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_Sans_bb89b5';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/2e0360740a537b02-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_Sans_bb89b5';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e013a9b384a02d35-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_Sans_bb89b5';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/97b6577f7d56ad22-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Nunito_Sans_Fallback_bb89b5';src: local("Arial");ascent-override: 99.39%;descent-override: 34.70%;line-gap-override: 0.00%;size-adjust: 101.72%
}.__className_bb89b5 {font-family: '__Nunito_Sans_bb89b5', '__Nunito_Sans_Fallback_bb89b5';font-weight: 400;font-style: normal
}

/* thai */
@font-face {
  font-family: '__Maitree_577ea2';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/b5eaf1e1f83a2954-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Maitree_577ea2';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3af23731a34f346b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Maitree_577ea2';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/4ee653ca59c4e714-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Maitree_577ea2';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/80cf8aae469a382c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Maitree_Fallback_577ea2';src: local("Times New Roman");ascent-override: 97.85%;descent-override: 42.54%;line-gap-override: 0.00%;size-adjust: 117.53%
}.__className_577ea2 {font-family: '__Maitree_577ea2', '__Maitree_Fallback_577ea2';font-weight: 500;font-style: normal
}

/* thai */
@font-face {
  font-family: '__Maitree_54afb6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7f51ce006c61ba63-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Maitree_54afb6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/eaca0311b07f8476-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Maitree_54afb6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/8a6e9cb6b2bae1e5-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Maitree_54afb6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/6b86e1d722146340-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Maitree_Fallback_54afb6';src: local("Times New Roman");ascent-override: 97.85%;descent-override: 42.54%;line-gap-override: 0.00%;size-adjust: 117.53%
}.__className_54afb6 {font-family: '__Maitree_54afb6', '__Maitree_Fallback_54afb6';font-weight: 300;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_Sans_45514b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/91da210830fde368-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_Sans_45514b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/2e89d1420926fd62-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_Sans_45514b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/1df443147fda9dd6-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_Sans_45514b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/5ac4b55274d78358-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_Sans_45514b';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e15b1cfda85f6579-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Nunito_Sans_Fallback_45514b';src: local("Arial");ascent-override: 99.39%;descent-override: 34.70%;line-gap-override: 0.00%;size-adjust: 101.72%
}.__className_45514b {font-family: '__Nunito_Sans_45514b', '__Nunito_Sans_Fallback_45514b';font-weight: 300;font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/75cde960c2842862-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1ff84563a719c397-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4c7655c11f7bd97b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Anton_Fallback_6fce18';src: local("Arial");ascent-override: 127.81%;descent-override: 35.76%;line-gap-override: 0.00%;size-adjust: 92.04%
}.__className_6fce18 {font-family: '__Anton_6fce18', '__Anton_Fallback_6fce18';font-weight: 400;font-style: normal
}

